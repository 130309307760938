import { Card, Col, ListGroup, Row } from "react-bootstrap";
import axios from "axios";
import vs from '../../images/profile/payment.png';
import assete from '../../images/wallet/assete.png';
import broker from '../../images/wallet/broker.png';
import home from '../../images/wallet/home.png';
import maining from '../../images/wallet/maining.png';
import {Link} from 'react-router-dom';
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Footer = () => {
  const { t } = useTranslation();
  var d = new Date();
  return (
    <div className="footer m-4 text-black">


        <footer className="mobilefutshow">
            <div className="footer-nav-wrapper">

                <Link className="footer-nav-single" to={"/"}>
                    <div className="menu-wrapper">
                        <img  alt="images" width="28" height="28" src={home} />  
                        <span style={{ color: "#2c2c2c" }}>Home</span>
                    </div>
                </Link>

                <Link className="footer-nav-single" to={"/sort-trading/?carency=btc"}>
                    <div className="menu-wrapper">
                        <img  alt="images" width="28" height="28" src={broker} />  
                        <span style={{ color: "#2c2c2c" }}>Broker</span>
                    </div>
                </Link>

                <Link className="footer-nav-single" to={"/app-asset"}>
                    <div className="menu-wrapper">
                        <img  alt="images" width="28" height="28" src={assete} />  
                        <span style={{ color: "#2c2c2c" }}>Asset</span>
                    </div>
                </Link>
                
                <Link className="footer-nav-single" to={"/free-mining"}>
                    <div className="menu-wrapper">
                         <img  alt="images" width="28" height="28" src={maining} />  
                        <span style={{ color: "#2c2c2c" }}>Mining</span>
                    </div>
                </Link>

            </div>
        </footer>


    </div>
  );
};

export default Footer;
