import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import loading_img from "../../../../images/profile/loading.gif";
import galaxy_icon from "../../../../images/galaxy_icon.png";

import main_wallet from "../../../../images/wallet/main-wallet.png";
import principal_icon from "../../../../images/wallet/principal-icon-w.png";
import ai_icone from "../../../../images/wallet/ai-icone.png";
import ai_trading from "../../../../images/wallet/sponcor-w.png";
import clube_income from "../../../../images/wallet/clube-income.png";
import salarry_icon from "../../../../images/wallet/salarry-icon.png";
import sponcor_w from "../../../../images/wallet/sponcor-w.png";
import diposit_wallet from "../../../../images/wallet/diposit-wallet.png";
import total_incom from "../../../../images/wallet/total-incom.png";
import total_withdra from "../../../../images/wallet/withdrow-icome.png";

import { GlobalSet } from '../../global/GlobalProvider';
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
const Home = () => {
    document.title = "Sgntl Home";
  const { BaseUrl } = GlobalSet();
  const [loading, setLoading] = useState(true);



   const [homedata, setHomeData] = useState([]);  
  /*.....product code........*/
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/home-data',data)
      .then(response=>{
         setHomeData(response.data.data_info)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);



return(
     <>
      <span style={{  marginTop: '-36px' }} className="d-xl-block">

        <div className="row">
            <Fragment>

           <Col xl="12" className="p-0" >


                <div className="col-xl-12">
                  <div className="row mydivpadding">
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img alt="images" width="28" height="28" src={main_wallet} />      
                              <h2 className="mb-0 font-w600">${ homedata.balance }</h2>
                              <p className="mb-0 text-nowrap">Main Wallet</p>    
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img alt="images" width="28" height="28" src={diposit_wallet} />  
                              <h2 className="mb-0 font-w600">$ { homedata.reserve_balance }</h2>
                              <p className="mb-0 text-nowrap ">Deposit Wallet</p>         
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img alt="images" width="28" height="28" src={principal_icon} />     
                              <h2 className="mb-0 font-w600">$ { homedata.investing_wallet }</h2>
                              <p className="mb-0 text-nowrap ">Principal Wallet</p>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                         <div style={{ textAlign: 'center'}}>
                            <img alt="images" width="28" height="28" src={galaxy_icon} />  
                              <h2 className="mb-0 font-w600"> { homedata.coin } GLX </h2>
                              <p className="mb-0">GLX Coin</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img  alt="images" width="28" height="28" src={ai_icone} />     
                              <h2 className="mb-0 font-w600">${ homedata.daily_return }</h2>
                              <p className="mb-0">AI Trading Income</p> 
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img  alt="images" width="28" height="28" src={ai_icone} /> 
                              <h2 className="mb-0 font-w600">${ homedata.refer_inc }</h2>
                              <p className="mb-0">AI Affiliate Income</p> 
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img  alt="images" width="28" height="28" src={clube_income} />  
                              <h2 className="mb-0 font-w600">${ homedata.club_income }</h2>
                              <p className="mb-0">Club Income</p>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img  alt="images" width="28" height="28" src={salarry_icon} />  
                              <h2 className="mb-0 font-w600">${ homedata.rank_income }</h2>
                              <p className="mb-0">Salary Income</p>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img  alt="images" width="28" height="28" src={sponcor_w} />  
                              <h2 className="mb-0 font-w600">${ homedata.team_inc }</h2>
                              <p className="mb-0">Sponsor Income</p>
                            </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img  alt="images" width="28" height="28" src={diposit_wallet} />  
                              <h2 className="mb-0 font-w600">${ homedata.total_deposit }</h2>
                              <p className="mb-0">Total Deposit</p>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <Link to="/income-history">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img  alt="images" width="28" height="28" src={total_incom} />  
                              <h2 className="mb-0 font-w600">${ homedata.total_Income }</h2>
                              <p className="mb-0">Total Income</p>
                            </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                            <div style={{ textAlign: 'center'}}>
                             <img  alt="images" width="28" height="28" src={total_withdra} />  
                              <h2 className="mb-0 font-w600">${ homedata.total_withdraw }</h2>
                              <p className="mb-0">Total Withdrawal</p>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

             </Col>


          </Fragment>
        </div> 
      </span>
    </>
  )
}
export default Home;