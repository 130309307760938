import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import off_button from "../../../../images/off-button.png";
import off_pc from "../../../../images/galaxy_icon.png";
import on_button from "../../../../images/on-button.png";
import on_pc from "../../../../images/logo_animgg.gif";
import swal from "sweetalert";

const Compose = () => {
  const history = useHistory();
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const [Timefreee, setTimefreee] = useState("");
  const [user, setUser] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState("");

  const fetchUserData = () => {
    const auth_token = localStorage.getItem("auth_token");
    const selectedCurrency = localStorage.getItem("selectedCurrency");
    const data = {
      key_s: "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9",
      currency: selectedCurrency,
    };
    if (auth_token !== null) {
      axios
        .post("/apps-profile", data)
        .then((response) => {
          setUser(response.data);
          setTimefreee(response.data.data.free_ai_data_end);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (!Timefreee) return;

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const countDownDate = new Date(Timefreee).getTime();
      const distance = countDownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimeRemaining("EXPIRED");
      } else {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [Timefreee]);

  const handleButtonClick = () => {
    const data = {
      key_s: "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9",
    };
    const auth_token = localStorage.getItem("auth_token");
    if (auth_token !== null) {
      axios
        .post("/ai-status", data)
        .then((response) => {
          setUser(response.data);
          fetchUserData(); // Refetch user data to update the timer
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let projectTypesdd = null;
  let AiStatus;
  if (user == "") {
    console.log(user);
  } else {
    AiStatus = user.data.free_ai_status;
    console.log(AiStatus);
    projectTypesdd = (
      <>
        <div style={{ background: "radial-gradient(circle, #ff9900, #2a2462)" }} className="free-ai-container">
          <h4 className="free-ai-header">Free Mining</h4>
          <a onClick={handleButtonClick}>
            <img className="free-ai-button" src={AiStatus === 1 ? on_button : off_button} alt="Button" />
          </a>
          <div style={{ textAlign: "center", color: "#ffffff" }}>
            <h5 className="free-ai-header"> Time remaining: {timeRemaining}</h5>
          </div>          
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12 col-lg-4">
                  <div className="email-left-box">{projectTypesdd}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-4">
                  <div className="email-left-box">
                    <div className="container mt-4">
                      <div className="row d-flex justify-content-center" style={{ background: "radial-gradient(white, #000e54)", borderRadius: "28px" }}>
                        <img style={{ width: "90%" }} className="form-inline" src={AiStatus === 1 ? on_pc : off_pc} alt="Button" />
                      </div>
                    </div>
                    <p style={{ padding: "10px", textAlign: "center", color: "#ff7600" }}>
                      Crypto robot trading leverages automated algorithms to execute trades in cryptocurrency markets, aiming to maximize profits and minimize risks by responding swiftly to market changes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Compose;
