import React, { Fragment, useState, useReducer } from "react";
import { Link , useHistory } from "react-router-dom";

import axios from "axios";
import { useEffect } from 'react';
import profile from "../../../../images/profile/profile.png";
import logout_img from "../../../../images/logout.png";
import { Dropdown } from "react-bootstrap";
import swal from "sweetalert";

const Compose = () => {
 const history = useHistory();
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post(`/logout`).then(res => {
          console.log(res.data.message)
            if(res.data.message === 'Unauthenticated')
            {
                localStorage.removeItem('auth_token');
                swal("Success",res.data.message,"success");
                history.push('/login');
            }
        });
    }

  const [user, setUser] = useState([]);
    useEffect(()=>{
      const auth_token = localStorage.getItem('auth_token');
      const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/apps-profile',data)
         .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])



  return (
    <Fragment>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
        <div className="row">
          <div className="col-xl-12 col-lg-4">
            <div className="email-left-box">

            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              Wallet
              </Link>
              <Link to="/app-profile" className="list-group-item">
              <i className="fas fa-donate font-18 align-middle me-2"></i>
              My Profile
              </Link>
              <Link to="/app-asset" className="list-group-item">
              <i className="fas fa-donate font-18 align-middle me-2"></i>
              My Asset
              </Link>
              <Link to="/deposit" className="list-group-item">
              <i className="fas fa-credit-card font-18 align-middle me-2"></i>
              Recharge
              </Link>
              <Link to="/migration-log" className="list-group-item">
              <i className="fas fa-id-card font-18 align-middle me-2"></i>
              AI Trading Report
              </Link>
              <Link to="/withdraw" className="list-group-item">
              <i className="fas fa-archive  font-18 align-middle me-2"></i>
              Withdrawal
              </Link>
            </div>


            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              Report
              </Link>
              <Link to="/income-history" className="list-group-item">
              <i className="fas fa-file-alt font-18 align-middle me-2"></i>
              Income History
              </Link>
              <Link to="/transaction-history" className="list-group-item">
              <i className="mdi mdi-file-document-box font-18 align-middle me-2"></i>
              Transaction History
              </Link>
            </div>


            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              Team
              </Link>
              <Link to="/my-smart-team" className="list-group-item">
              <i className="mdi mdi-file-document-box font-18 align-middle me-2"></i>
              My Team
              </Link>
            </div>



            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              Promotion
              </Link>
              <Link  to="/affiliate" className="list-group-item">
              <i className="fas fa-bullhorn font-18 align-middle me-2"></i>
              Promotion
              </Link>
            </div>

            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              Support
              </Link>
              <Link  to="/support-ticket" className="list-group-item">
              <i className="fas fa-bullhorn font-18 align-middle me-2"></i>
              Support Ticket
              </Link>
            </div>



            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              Security
              </Link>
              <Link  to="/security" className="list-group-item">
              <i className="fas fa-bullhorn font-18 align-middle me-2"></i>
              Security
              </Link>
            </div>


            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              Account Security
              </Link>
              <Link to="/change-password" className="list-group-item">
              <i className="fa fa-key font-18 align-middle me-2"></i>
              Reset Password
              </Link>
            </div>




            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              About
              </Link>
              <Link className="list-group-item">
              <i className="fas fa-ban font-18 align-middle me-2"></i>
              Privacy Policy
              </Link>
              <Link  className="list-group-item">
              <i className="fas fa-bug font-18 align-middle me-2"></i>
              Risk Disclosure Agreement
              </Link>
            </div>

            <div className="mail-list rounded  mt-4">
              <Link className="list-group-item active bg-warning">
              <i className="fas fa-star font-18 align-middle me-2"></i>
              About
              </Link>
              <Link className="list-group-item">
              <i className="font-18 align-middle me-2">
               <img alt="images" width={17} src={logout_img} /></i>
               <span onClick={logoutSubmit}> Logout </span>
              </Link>
            </div>



            </div>
          </div>
        </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Compose;
