import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { Link ,useHistory} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import { useEffect } from 'react';
//** Import Image */
import offer_gif from "../../../../images/Comingsoon.png";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const initialState = false;
const reducer = (state, action) =>{
  switch (action.type){
    case 'sendMessage':
      return { ...state, sendMessage: !state.sendMessage }    
    case 'postModal':
      return { ...state, post: !state.post }
    case 'linkModal':
      return { ...state, link: !state.link }    
    case 'cameraModal':
      return { ...state, camera: !state.camera }    
    case 'replyModal':
      return { ...state, reply: !state.reply }
    default:
      return state  
  } 
}


  const AppProfile = () => {
  const { CurrencySymbol } = GlobalSet();
  const { t } = useTranslation();
 
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Fragment>

      <div className="row">
        <div className="col-lg-12" style={{textAlign : 'text-align'}}>
          <img style={{ width:"90%", margin: "0px auto" }} className="form-inline" src={offer_gif} alt=""/>
        </div>
      </div>



    </Fragment>
  );
};

export default AppProfile;
