const data = {
    "data": [
        {
            "id": 1,
            "name": "All Game",
            "slug": "/sort-trading/?cu=all-game",
            "photo": "appssource/sports_icons/Bowls.png"
        },
        {
            "id": 2,
            "name": "Slots",
            "slug": "/sort-trading/?cu=slots",
            "photo": "appssource/sports_icons/slots.png"
        },
        {
            "id": 3,
            "name": "Poker",
            "slug": "/sort-trading/?cu=poker",
            "photo": "appssource/sports_icons/poker.png"
        },
        {
            "id": 4,
            "name": "Live Casino",
            "slug": "/sort-trading/?cu=live-casino",
            "photo": "appssource/sports_icons/live_casino.png"
        }
    ]
}
export default data;