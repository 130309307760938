import React,{Fragment,useContext, useEffect,useState, useRef } from 'react';
import { useParams, Router, useLocation, Link } from "react-router-dom";
import currency_data from './currency';
import axios from "axios";
import { Row, Col, Card, Carousel, Accordion, ButtonGroup, Button } from 'react-bootstrap'
import swal from "sweetalert";


const Home = () => {
const location = useLocation();
           console.log(location)
   const [selectedPaymentTyp, setSelectedPaymentTtyp] = useState('one_time');
   const [OnHomeGame, OneGame] = useState([]); 
   const handleSubmit = (event) => {
        // Handle form submission
        event.preventDefault();
        // You can add your form submission logic here
    };
  // ifram full screen
  const videoRef = useRef(null);

  const var_currency= currency_data.data;

  const handleToggleFullScreen = () => {
    const video = videoRef.current;

    if (video) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    }
  }; 
  // ifram full screen end



  useEffect(() => {
    const loadTradingViewWidget = async () => {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/tv.js';
      script.async = true;
      script.onload = () => {
        new window.TradingView.widget({
          width: 'auto',
          height: 450,
          symbol: 'BYBIT:BTCUSDT',
          interval: '5',
          timezone: 'Etc/UTC',
          theme: 'dark',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          hide_top_toolbar: true,
          hide_legend: true,
          save_image: false,
          container_id: 'cryptofeture_map'
        });
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadTradingViewWidget();
  }, []);


return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">

    <div className="col-12">
      <div className="card">

              <Col xl="12" className="p-0" >
               <div className="App">
                  <div className="table-responsive card booking">
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                           style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', background: '#b2b4b5'}}
                        >
                          <tbody>
                            <tr className="odd" role="row">
                              { var_currency.map((product) => (
                                   <td> <Link key={product.id} to={product.slug} className="text-black">{product.name}</Link></td>
                                ))   
                              }
                            </tr>
                          </tbody>
                        </table>
                      </div>
                </div>
              </Col>

          <Col xl="12" className="p-0" >
                <div className="form-inline">

                    <div id="cryptofeture_map">
                      <div
                        id="tradingview_c98bb-wrapper"
                        style={{
                          position: 'relative',
                          boxSizing: 'content-box',
                          width: 'auto',
                          height: 450,
                          margin: '0 auto !important',
                          padding: '0 !important',
                          fontFamily:
                            "-apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif"
                        }}
                      >
                        <div
                          style={{
                            width: 'auto',
                            height: 450,
                            background: 'transparent',
                            padding: '0 !important'
                          }}
                        >
                          <iframe
                            id="tradingview_c98bb"
                            src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_c98bb&amp;symbol=BYBIT%3ABTCUSDT&amp;interval=5&amp;hidetoptoolbar=1&amp;hidelegend=1&amp;saveimage=0&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;theme=dark&amp;style=1&amp;timezone=Etc%2FUTC&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en&amp;utm_source=cryptofeture.com&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=BYBIT%3A"
                              style={{
                              width: '100%',
                              height: '100%',
                              margin: '0 !important',
                              padding: '0 !important'
                            }}
                            frameBorder="0"
                            allowtransparency="true"
                            scrolling="no"
                            allowFullScreen=""
                          ></iframe>
                        </div>
                      </div>
                    </div>

                </div>
          </Col>


        <div className="col-lg-12">
                <hr/>

                        <div className="row" style={{margin: "0px auto"}}>
                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#ddd", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">1 MNT</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="payment_type"
                                                        value="one_time"
                                                        checked={selectedPaymentTyp === "one_time"}
                                                        onChange={() => setSelectedPaymentTtyp("one_time")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#ddd", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">5 MNT</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="payment_type"
                                                        value="two_time"
                                                        checked={selectedPaymentTyp === "two_time"}
                                                        onChange={() => setSelectedPaymentTtyp("two_time")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#ddd", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">10 MNT</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="payment_type"
                                                        value="three_time"
                                                        checked={selectedPaymentTyp === "three_time"}
                                                        onChange={() => setSelectedPaymentTtyp("three_time")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        

            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between flex-wrap">
                            <div className="header-title">
                                <h4 className="card-title">Quick Trade</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="col-lg-12">
                                <div className="form-group mb-3">
                                    <div className="input-group pt-1">
                                        <span className="input-group-text" id="basic-addon3">USDT</span>
                                        <input type="text" className="form-control col-lg-8" placeholder="0,000000" name="usdt" aria-label="Recipient's username" aria-describedby="basic-addon3" />
                                        <input type="hidden" placeholder="0,000000" name="now_coin" aria-label="Recipient's username" aria-describedby="basic-addon3" />
                                    </div>
                                </div>
                              <div className="">
                                  <div className="d-flex justify-content-between"> 
                                      <button className="btn btn-success" name="buy_option" value="up" type="submit">
                                          <span>BUY ( 1.95 )</span>
                                          <svg className="rotate-45" width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.75 11.7256L4.75 11.7256" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M13.7002 5.70124L19.7502 11.7252L13.7002 17.7502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                      </button>
                                      <button className="btn btn-danger" name="buy_option" value="down" type="submit">
                                          <span>SELL ( 1.95 )</span>
                                          <svg className="rotate-45" width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.25 12.2744L19.25 12.2744" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                      </button>
                                  </div>
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-block card-stretch custom-scroll">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table data-table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Coin Name</th>
                                            <th scope="col">Prince</th>
                                            <th scope="col">Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="white-space-no-wrap">
                                            <td className="pe-2">20</td>
                                            <td className="pe-2">BTC</td>
                                            <td className="pe-2">6023.564</td>
                                            <td className="pe-2">USDT</td>
                                        </tr>
                                        <tr className="white-space-no-wrap">
                                            <td className="pe-2">20</td>
                                            <td className="pe-2">BTC</td>
                                            <td className="pe-2">6023.564</td>
                                            <td className="pe-2">USDT</td>
                                        </tr>
                                        <tr className="white-space-no-wrap">
                                            <td className="pe-2">20</td>
                                            <td className="pe-2">BTC</td>
                                            <td className="pe-2">6023.564</td>
                                            <td className="pe-2">USDT</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        </div>

      </div>
    </span>
    </>
  )
}
export default Home;