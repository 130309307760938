import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import loading_img from "../../../images/profile/loading.gif";


import Broker from "../../../images/Broker.png";
import logo_anim from "../../../images/logo_animgg.gif";
import Game from "../../../images/Game.png";
import Social_Media from "../../../images/Social_Media.png";
import Node from "../../../images/Node.png";
import Coin_Listing from "../../../images/Coin_Listing.png";
import Airdrop from "../../../images/Airdrop.png";
import Real_Estate from "../../../images/Real_Estate.png";
import Metaverse from "../../../images/Metaverse.png";
import NFT from "../../../images/NFT.png";
import Bill_Pay from "../../../images/Bill_Pay.png";
import Galaxy_Debit_Card from "../../../images/Galaxy_Debit_Card.png";
import Galaxy_Exchanger from "../../../images/Galaxy_Exchanger.png";

import { GlobalSet } from '../global/GlobalProvider';
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
const Home = () => {
    document.title = "Galaxymining Home";
    const { changeBackground } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "light", label: "Light" });
    }, []);
  const { BaseUrl } = GlobalSet();
  const [tradingWallet, setTradingWallet] = useState(0);
  const [tradestatus, setTradeStatus] = useState([]);
  let incrementInterval;
  const [loading, setLoading] = useState(true);

  const incrementTradingWallet = () => {
    const randomFactor = (Math.random() - 0.5) * 0.8 / 100;
    setTradingWallet(prevValue => parseFloat((prevValue + prevValue * randomFactor).toFixed(2)));
  };


  useEffect(() => {
    if (!loading && tradestatus === 1) {
      incrementInterval = setInterval(incrementTradingWallet, 1000);
    }
    return () => {
      clearInterval(incrementInterval);
    };
  }, [loading, tradestatus]);

   const [homedata, setHomeData] = useState([]);  
   const [salider, setSalider] = useState([]);  
   const [cryptoData, setCryptoScroll] = useState([]);  
  /*.....product code........*/
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/home-data',data)
      .then(response=>{
         setSalider(response.data.slider)
         setHomeData(response.data.data_info)
         setTradingWallet(response.data.trading_wallet)
         setCryptoScroll(response.data.live_crypto_point)
         setTradeStatus(response.data.trade_status)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);



  useEffect(() => {
    const loadTradingViewWidget = async () => {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/tv.js';
      script.async = true;
      script.onload = () => {
        new window.TradingView.widget({
          width: 'auto',
          height: 290,
          symbol: 'BYBIT:BONKUSDT',
          interval: '5',
          timezone: 'Etc/UTC',
          theme: 'light',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          hide_top_toolbar: true,
          hide_legend: true,
          save_image: false,
          container_id: 'cryptofeture_map'
        });
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadTradingViewWidget();
  }, []);


    
//mobile odds option
  const casino_game_s = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                },
            },
        ],
  };




return(
     <>
      <span style={{  marginTop: '-36px' }} className="d-xl-block">

        <div className="row">
            <Fragment>

           <Col xl="12" className="p-0" >
              <div className="row align-items-center mb-3">
                <div className="row">
                  <marquee behavior="scroll" style={{ width: '95%', float: 'right' }} direction="left" scrollamount="4">
                    {cryptoData.map((crypto, index) => (
                      <button
                        key={index}
                        style={{ background: '#2c005c', marginLeft: '3px', marginRight: '3px', borderRadius: '5px', color: 'white', width: '150px' }}
                        className="cart"
                      >
                        {crypto.coin_name} {crypto.coin_value} <br /> <img style={{ width: '22px' }} src={BaseUrl+'public/user_icon/'+(crypto.icon)} alt={`${crypto.coin_name} icon`} />
                      </button>
                    ))}
                  </marquee>
                </div>
              </div>


                  <div className="container">

                    <div className="row d-flex justify-content-center">

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/deposit">
                        <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-credit-card" aria-hidden="true"></i>
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>Deposit</h6>
                        </div>
                       </Link>
                      </div>

                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/trading">
                        <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-industry" aria-hidden="true"></i>                  
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>AI Trading</h6>
                        </div>
                        </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/my-smart-team">
                         <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-users" aria-hidden="true"></i>                   
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>Smart Team</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/withdraw">
                         <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-window-maximize" aria-hidden="true"></i>           
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>Withdraw</h6>
                        </div>
                         </Link>
                      </div>
                    


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/affiliate">
                        <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-bullhorn" aria-hidden="true"></i>     
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>To Invite</h6>                 
                        </div>
                         </Link>
                      </div>                      

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/">
                         <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-file" aria-hidden="true"></i>           
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>White Paper</h6>
                        </div>
                         </Link>
                      </div>
                    
                    <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                      <a href="https://galaxymining.io/"  target="_blank"  rel="noopener noreferrer">
                        <div className="card booking p-2 rounded" style={{ textAlign: 'center', width: '80px', border: '1px solid gold' }}>
                          <i style={{ fontSize: '24px', color: '#ff9900' }} className="fa fa-globe" aria-hidden="true"></i>
                          <h6 className="mt-1" style={{ color: '#4d4d4d', fontSize: '10px' }}>Website</h6>
                        </div>
                      </a>
                    </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/">
                         <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-car" aria-hidden="true"></i>           
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>Animation</h6>
                        </div>
                         </Link>
                      </div>
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                         <a href="https://galaxymining.io/document/GALAXY.pdf"  rel="noopener noreferrer">
                         <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-book" aria-hidden="true"></i>           
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>Galaxy Plan</h6>
                        </div>
                          </a>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/coming-soon">
                         <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-gamepad" aria-hidden="true"></i>           
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>Game</h6>
                        </div>
                         </Link>
                      </div>
                    

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/support-ticket">
                         <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="far fa-envelope-open" aria-hidden="true"></i>
                          <h6 className="mt-1"  style={{color: '#4d4d4d', fontSize: '10px'}}>Support</h6>
                        </div>
                       </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        
                         <a href="https://galaxymining.io/contact.html"  target="_blank"  rel="noopener noreferrer">
                         <div className="card booking p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-phone-square" aria-hidden="true"></i>           
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Contact</h6>
                        </div>
                          </a>
                      </div>


                    </div>
                  </div>


             </Col>


             <Carousel className="p-0">
                {salider.map((carousel, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={BaseUrl+(carousel.img_link)}
                      className='d-block w-100'
                      alt={`Slide ${i + 1}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel> 

                  <div className="container mb-4">
                  <br/>
                    <div className="row d-flex justify-content-center" style={{background : "#00075a", borderRadius : '3px'}}>

                       <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 mt-3 d-flex justify-content-center">
                        <Link to="/coming-soon">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fa fa-life-ring" aria-hidden="true"></i>
                          <h6 className="mt-1"  style={{color: '#ffffff', fontSize: '10px'}}>Blockchain</h6>
                        </div>
                       </Link>
                      </div>

                      <img style={{ width:"32%" }} className="form-inline" src={ logo_anim } alt=""/>
                    

  




                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 mt-3 d-flex justify-content-center">
                        <Link to="/convert-balance">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <i style={{fontSize : "24px", color : "#ff9900"}} className="fas fa-exchange-alt" aria-hidden="true"></i>
                          <h6 className="mt-1"  style={{color: '#ffffff', fontSize: '10px'}}>Convert</h6>
                        </div>
                       </Link>
                      </div>
                    </div>
                  </div>


              <Col xl="12" className="p-0" >
                    <div className="form-inline">

                        <div id="cryptofeture_map">
                          <div
                            id="tradingview_c98bb-wrapper"
                            style={{
                              position: 'relative',
                              boxSizing: 'content-box',
                              width: 'auto',
                              height: 290,
                              margin: '0 auto !important',
                              padding: '0 !important',
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif"
                            }}
                          >
                            <div
                              style={{
                                width: 'auto',
                                height: 290,
                                background: 'transparent',
                                padding: '0 !important'
                              }}
                            >
                              <iframe
                                id="tradingview_c98bb"
                                src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_c98bb&amp;symbol=BYBIT%3ABONKUSDT&amp;interval=5&amp;hidetoptoolbar=1&amp;hidelegend=1&amp;saveimage=0&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;theme=dark&amp;style=1&amp;timezone=Etc%2FUTC&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en&amp;utm_source=cryptofeture.com&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=BYBIT%3ABNBUSDT"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  margin: '0 !important',
                                  padding: '0 !important'
                                }}
                                frameBorder="0"
                                allowtransparency="true"
                                scrolling="no"
                                allowFullScreen=""
                              ></iframe>
                            </div>
                          </div>
                        </div>

                    </div>
              </Col>



                  <div className="container">

                    <div className="row d-flex justify-content-center mt-3">

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Broker} />    
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Broker</h6>
                        </div>
                       </Link>
                      </div>
                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Game} />    
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Game</h6>                 
                        </div>
                         </Link>
                      </div>
                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Social_Media} />                 
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Social Media</h6>
                        </div>
                        </Link>
                      </div>

                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Node} />                   
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Node</h6>
                        </div>
                        </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/coming-soon">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Coin_Listing} />                  
                          <h6 className="mt-1"  style={{color: '#535353', fontSize: '10px'}}>Coin Listing</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Airdrop} />    
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Airdrop</h6>
                        </div>
                       </Link>
                      </div>
                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Real_Estate} />    
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Real Estate</h6>                 
                        </div>
                         </Link>
                      </div>
                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Metaverse} />                  
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Metaverse</h6>
                        </div>
                        </Link>
                      </div>


                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/coming-soon">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={NFT} />                    
                          <h6 className="mt-1"  style={{color: '#535353', fontSize: '10px'}}>NFT</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Bill_Pay} />    
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Bill Pay</h6>
                        </div>
                       </Link>
                      </div>
                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Galaxy_Debit_Card} />       
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Debit Card</h6>                 
                        </div>
                         </Link>
                      </div>
                    
                      <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/coming-soon">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', width : '80px', border: '1px solid gold' }}>
                          <img  alt="images" width="32" height="32" src={Galaxy_Exchanger} />                  
                          <h6 className="mt-1"  style={{color: '##4d4d4d', fontSize: '10px'}}>Exchanger</h6>
                        </div>
                        </Link>
                      </div>




                    </div>
                  </div>



          </Fragment>
        </div> 
      </span>
    </>
  )
}
export default Home;